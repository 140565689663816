import styled from "styled-components";

export const LinkWrapper = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
`;

export const LogoImage = styled.img`
    width: 128px;
    margin-bottom: 10px;
`;

export const LogoContainer = styled.div`
    margin-bottom: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
