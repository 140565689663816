import { JSZip } from "js/vendor";

import getPPTTheme from "js/core/utilities/getPPTTheme";

const MAX_FILE_SIZE_BYTES = 512 * 1024 * 1024; // 512 MB

class FileIsTooLargeError extends Error {
}

function getDocumentAsCompressed() {
    return new Promise((resolve, reject) =>
        Office.context.document.getFileAsync(
            Office.FileType.Compressed,
            { sliceSize: 65536 /*64 KB*/ },
            ({ status, value: file, error }) => {
                if (status == "succeeded") {
                    resolve(file);
                } else {
                    reject(new Error(`Failed to receive file, status: ${status}, error: ${error?.message}`));
                }
            })
    );
}

async function getPPTFileStream() {
    const file = await getDocumentAsCompressed();

    if (file.size > MAX_FILE_SIZE_BYTES) {
        throw new FileIsTooLargeError(`File size is ${file.size / 1024 / 1024}MB which exceeds max allowed file size ${MAX_FILE_SIZE_BYTES / 1024 / 1024}MB`);
    }

    let currentSliceIdx = 0;
    const stream = new ReadableStream({
        start(controller) {
            // noop, will be pulling data on request
        },
        pull(controller) {
            return new Promise((resolve, reject) => {
                if (currentSliceIdx === file.sliceCount) {
                    controller.close();
                    resolve();
                    return;
                }

                file.getSliceAsync(currentSliceIdx, ({ status, value: slice, error }) => {
                    currentSliceIdx++;
                    if (status === "succeeded") {
                        controller.enqueue(slice.data);
                        resolve();
                    } else {
                        const err = new Error(`Failed to receive file slice #${currentSliceIdx}, status: ${status}, error: ${error?.message}`);
                        controller.error(err);
                        reject(err);
                    }
                });
            });
        }
    });

    return stream;
}

async function unzipPPTFileStream(stream) {
    // NOTE: we're loading the entire file in memory, we should
    // try feeding the unzipper with the stream instead
    const reader = stream.getReader();
    const data = [];
    while (true) {
        const { done, value } = await reader.read();
        if (value) {
            data.push(...value);
        }
        if (done) {
            break;
        }
    }

    const zip = new JSZip();
    await zip.loadAsync(data);

    return zip;
}

async function parsePPTTheme() {
    const stream = await getPPTFileStream();
    const zip = await unzipPPTFileStream(stream);
    return getPPTTheme(zip);
}

export default parsePPTTheme;
