function getCurrentPPTSlideData() {
    return new Promise((resolve, reject) => {
        Office.context.document.getSelectedDataAsync(Office.CoercionType.SlideRange, asyncResult => {
            try {
                if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                    reject(asyncResult.error);
                } else {
                    resolve({
                        currentPPTSlideId: asyncResult.value.slides[0].id,
                        currentPPTSlideIndex: asyncResult.value.slides[0].index - 1
                    });
                }
            } catch (error) {
                reject(error);
            }
        });
    });
}

export default getCurrentPPTSlideData;
