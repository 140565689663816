async function getBaiDataFromPPTSlide(pptSlideIndex) {
    let currentSlideModel, currentSlidePlaybackStage, currentThemeModel, presentationThemeModel;
    await PowerPoint.run(async context => {
        const presentation = context.presentation;
        const slide = presentation.slides.getItemAt(pptSlideIndex);

        const slideModelTag = slide.tags.getItemOrNullObject("BAI_SLIDE_MODEL");
        slideModelTag.load("value");
        await context.sync();
        if (slideModelTag.value) {
            currentSlideModel = JSON.parse(slideModelTag.value);
        }

        const slideThemeModelTag = slide.tags.getItemOrNullObject("BAI_THEME_MODEL");
        slideThemeModelTag.load("value");
        await context.sync();
        if (slideThemeModelTag.value) {
            currentThemeModel = JSON.parse(slideThemeModelTag.value);
        }

        const slidePlaybackStageTag = slide.tags.getItemOrNullObject("BAI_SLIDE_PLAYBACK_STAGE");
        slidePlaybackStageTag.load("value");
        await context.sync();
        if (slidePlaybackStageTag.value) {
            currentSlidePlaybackStage = parseInt(slidePlaybackStageTag.value);
        }

        const presentationThemeModelTag = presentation.tags.getItemOrNullObject("BAI_THEME_MODEL");
        presentationThemeModelTag.load("value");
        await context.sync();
        if (presentationThemeModelTag.value) {
            presentationThemeModel = JSON.parse(presentationThemeModelTag.value);
        }
    });
    return { currentSlideModel, currentSlidePlaybackStage, currentThemeModel, presentationThemeModel };
}

export default getBaiDataFromPPTSlide;
