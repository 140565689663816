const lodash = require("lodash");
const removeTrailingNullsFromArrays = require("./removeTrailingNullsFromArrays");

function getSlidePlaybackStagesCount(slideModelAttributes) {
    if (slideModelAttributes.template_id === "textgrid_carousel") {
        const normalizedSlideModelAttributes = removeTrailingNullsFromArrays(lodash.cloneDeep(slideModelAttributes));
        return normalizedSlideModelAttributes.states[0].primary.items.length;
    }

    return 1;
}

module.exports = getSlidePlaybackStagesCount;
